.gallery-container{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-inline: 3vw;
	
}

.img-bottom-list{
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-top: 10px;
}

.img{
	width: 5vw;
	margin-inline: 0.2vw;
	border-radius: 5px;
	object-fit: cover;
}

.selected-img{
	margin-bottom: 5px;
	border-radius: 5px;
	object-fit: contain;
}

.img:hover{
	cursor: pointer;
	border-bottom: 2px solid white;
	margin-bottom: -2px;
}

@media screen and (max-width: 767px) {

	.selected-img{
		width: 90vw !important;
	}

	.img{
		width: 14vw;
	}
}