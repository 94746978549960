.flex-row-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-block: 4vw;
}

.flex-column-container{
	display: flex;
	flex-direction: column;
	align-self: flex-end;
}

@media screen and (max-width: 767px) {
	.flex-row-container{
		flex-direction: column;
	}
}

