.navbar{
    position: fixed;
		display: flex;
		justify-content: center;
		width: 100%;
    opacity: 0;
    padding-bottom: 4vw;
    transition: 0.2s;
}

.navbar:hover{
    color: white;
    opacity: 1;
}

.navbar-link{
    text-decoration: none;
		margin-inline: 3vw;
    font-size: 30px;
    color: white;
    opacity: .2;
    font-weight: 500;

    transition: 0.2s;

}

.navbar-link:hover{
    color: white;
    opacity: .8;
}

.navbar-link.active{
	opacity: 1;
	font-weight: 600;
}

