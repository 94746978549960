.pic-text-container{
	justify-content: space-evenly;
}

.title{
	color: white;
	font-size: 5vw;
}

.about-text{
	color: white;
	font-size: 2vw;
	text-align: justify;
	white-space: pre-line;
}

@media screen and (max-width: 767px) {
	
	
	.about-text{
		width: 100%;
		font-size: 5vw;
		text-align: left;
	}

	.portrait-image{
		width: 50vw !important;
	}

}

.portrait-image{
	width: 30vw;
	/* filter: grayscale(); */
}

.link{
	color: white;
	font-weight: 600;
	transition: .5s;
}

.link:hover{
	color: #5bb3ab;
}


