.education-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.school-year-text{
    font-size: 3vw;
    font-weight: 800;
    font-style: normal;
}

.education-container ul{
	justify-content: flex-end;
    color: white;
    font-size: 2vw;
    list-style: none;
}

.education-container li{
    text-align: right;
    font-style: italic;
}

@media screen and (max-width: 767px) {
	.education-container ul{
		font-size: 4vw;
	}

	.school-year-text{
		font-size: 6vw;
	}
	

}