.row{
	display: flex;
	flex-direction: row;
}

.project-information-container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 40px;
}

.title-container{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.project-img{
	width: 18vw;
	margin-inline: 2vw;
}

.project-title{
	color: rgb(255, 255, 255);
	font-size: 3.5vw;
	font-style: italic;
	margin: 0;
}

.company{
	color: white;
	font-size: 4vw;
	margin: 0;
}

.position{
	color: rgb(255, 255, 255);
	font-size: 2vw;
	margin: 0;
}


.time-frame{
	color: rgb(221, 221, 221);
	font-style: italic;
	font-size: 1.2vw;
	text-align: right;
	margin: 0;
}

.location{
	color: rgb(221, 221, 221);
	font-size: 1.2vw;
	font-style: italic;
	text-align: right;
	margin: 0;
}

.description{
	color: rgb(255, 255, 255);
	font-size: 1.5vw;
	text-align: left;
}

.technologies{
	color: rgb(173, 173, 173);
	font-weight: 400;
	font-size: 1vw;
}

@media screen and (max-width: 767px) {
	.project-information-container{
		margin: 0;
	}

	.project-title{
		font-size: 8vw;
	}

	.position{
		font-size: 4.5vw;
	}
	
	.time-frame{
		font-size: 3.5vw;
	}
	
	.location{
		font-size: 3.5vw;
	}
	
	.description{
		font-size: 4vw;
	}

	.project-img{
		width: 50vw;
	}

	.technologies{
		font-size: 3.5vw;
	}
}