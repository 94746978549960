.column-flex-container{
    display: flex;
    flex-direction: column;
}

.firstLine{
    text-align: start;
}

.secondLine{
    text-align: end;
		font-size: 16vw;
}

.thirdLine{
    text-align: end;
    font-size: 3vw;
    line-height: 4vw;
}


