.icon{
	text-align: center;
	font-size: 10vw;
	color: white;
	margin-block: 10vw;
}

.link-icon{
	font-size: 4vw;
	color: white;
}

.link-icon:hover{
  color: aqua;
}


@media screen and (max-width: 767px) {
	.icon{
		font-size: 12vw;
		margin-block: 20vw;
	}

	.link-icon{
		font-size: 8vw;
	}
}
