body {
	background-color: black;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
   	 monospace;
}

h1{
	font-size: 11vw;
	color: white;
	margin: 0px;
	line-height: 90%;
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 16vw;
	}
}
