

.links-container ul{
    color: white;
    font-size: 2vw;
    list-style: none;	
}

.links-container li{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.links-container a {

	font-weight: 600;
	text-decoration: none;
	color: white;
	font-size: 7vw;
}

.links-container a:hover {
	text-decoration: underline;
}

.icon-small{
	display: flex;
	font-size: 6vw;
	margin: 0;
	margin-right: 3vw;
}